import React, { useState } from 'react';
import { Box, Button, Text, VStack, Flex, Link, Image } from '@chakra-ui/react';
import { useClipboard } from '@chakra-ui/hooks';



const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const textToCopy = "0xfAF89d9b21740183DDF2E0110497dA1A32Bd52Ca";
  const { onCopy } = useClipboard(textToCopy);

  const toggleAudio = () => {
    // Toggle audio play/pause
    const audio = document.getElementById('background-audio');
    if (!isPlaying) {
      audio.play().catch(error => console.error('Audio playback failed:', error));
    } else {
      audio.pause();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <VStack spacing={0} align="center" marginTop="0"  backgroundColor="#F7F6F4"  minHeight="100vh" overflowX="hidden" >
        {/* <Flex width={["100%", "80%", "70%"]} justifyContent="flex-start"> */}
  <Flex justifyContent="center">
    <Image 
  src={process.env.PUBLIC_URL + '/breadeyes.png'} 
  width={["80%", "60%", "40%", "30%"]} // This will scale the image based on the viewport width
  maxWidth="200px" // This will ensure that the image doesn't get too large on big screens
  height="auto" // This will maintain the aspect ratio of the image
  objectFit="contain" // This will make sure the entire image is visible within the container
/>
<Image 
  src={process.env.PUBLIC_URL + '/base.png'} 
  width={["60%"]} // This will scale the image based on the viewport width
  maxWidth="2000px" // This will ensure that the image doesn't get too large on big screens
  height="auto" // This will maintain the aspect ratio of the image
  objectFit="contain" // This will make sure the entire image is visible within the container
/>
</Flex>
<Flex justifyContent="flex-end" padding={15}>
  <audio id="background-audio" loop>
    <source src={process.env.PUBLIC_URL + '/breadbreadbread.mp3'} type="audio/mpeg" />
    Your browser does not support the audio element.
  </audio>
  <Button
    onClick={toggleAudio}
    colorScheme="teal"
    size="10px"
    width='350px'
    padding="2" 
    fontSize={["0.75rem", "0.875rem", "1rem"]} 
    borderRadius="8px"
    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.2)"
  >
    {isPlaying ? 'No Bread' : 'Bread'}
  </Button>
</Flex>

<Flex
  direction={{ base: 'column', md: 'row' }} 
  align="center" 
  justify="center" 
  gap="20px"
  objectFit="cover"
>
  <Flex>
  <Box position="relative" width={{ base: "100%", md: "50%" }}>
    <video
      id="background-video"
      loop
      muted
      autoPlay
      playsInline
      style={{ width: "100%", maxHeight: "65vh", display: "block" }}
    >
      <source src={process.env.PUBLIC_URL + '/bread1.mp4'} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </Box>
  </Flex>
  <Flex
    direction="column" 
    alignItems='flex-start' // This change ensures alignment and consistency.
    width={{ base: "100%", md: "50%" }} // Adjusted for alignment.
    gap="20px"
  >
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center" >
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://dexscreener.com/base/0x6b7bda00044c4eef7447f9363d2dec70ee1fa7b7" color="teal.500" fontSize="6xl" fontWeight="bold">DEXSCREENER</Link>
    </Box>
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center">
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://basescan.org/token/0xfAF89d9b21740183DDF2E0110497dA1A32Bd52Ca" color="teal.500" fontSize="6xl" fontWeight="bold">BASE SCAN</Link>
    </Box>
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center">
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://twitter.com/breadonbase" color="teal.500" fontSize="6xl" fontWeight="bold">TWITTER</Link>
    </Box>
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center">
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://opensea.io/assets/ethereum/0x135c4e5e427ebed0f8bf7966cec4117b1cae2137/1" color="teal.500" fontSize="6xl" fontWeight="bold">ETH NFT</Link>
    </Box>
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center">
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://t.me/breadbreadbreadportal" color="teal.500" fontSize="6xl" fontWeight="bold">TELEGRAM</Link>
    </Box>
    <Box flex="1" minWidth="240px" maxWidth="calc(50% - 20px)" textAlign="center">
      <Image src={process.env.PUBLIC_URL + '/breadeyes.png'} boxSize="50px" m="auto" objectFit="cover" height="auto"/>
      <Link href="https://poly.cam/capture/2CB067AB-A663-4113-B324-CCF86E0F43A1" color="teal.500" fontSize="6xl" fontWeight="bold">3D BREAD</Link>
    </Box>
  </Flex>
 
</Flex>
<Text
  fontSize={["xs", "sm", "md", "lg"]} // Start even smaller if necessary
  fontWeight="bold"
  textAlign="center"

  width="100%" // Responsive to the container width
  maxWidth="600px" // Ensures the text doesn't get too wide on large screens
  overflow="hidden" // Hide overflow
  textOverflow="ellipsis" // Add an ellipsis to overflowing text
  whiteSpace="normal" // Allow the text to wrap
  onClick={onCopy}
  cursor="pointer"
  _hover={{ textDecoration: 'underline' }}
>
~~CLICK TO COPY~~  CONTRACT ADDRESS: {textToCopy}
</Text>


    </VStack>
  );
};

export default App;
